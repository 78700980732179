import React, { useState, useEffect, useCallback } from "react";
import Scrollspy from "react-scrollspy";
import { debounce } from "lodash"; // Import debounce from lodash

import {
  toc,
  tocList,
  isCurrent,
  container,
} from "./TableOfContents.module.scss";
import HeadingTableOfContents from "./HeadingTableOfContents";

const formatUrl = (url) => {
  if (typeof url !== 'string') {
    console.error('Invalid URL:', url);
    return '';
  }
  return url.replace(/\s+/g, "-").toLowerCase();
};

const TableOfContentsRenderer = ({ data, url, positionTop }) => (
  <nav
    className={toc}
    style={{ position: "fixed", top: positionTop, transition: "top 0.3s" }} // Add transition for smooth movement
  >
    <Scrollspy items={url} currentClassName={isCurrent} className={tocList}>
      {data.map((value) => (
        <li key={value.url} style={{ fontSize: value?.subMenu ? 13 : 14 }}>
          <a
            style={{ paddingLeft: value?.subMenu ? 32 : 20 }}
            href={`#${formatUrl(value.url)}`}
          >
            {value.title.length > 15
              ? value.title.slice(0, 15) + "..."
              : value.title}
          </a>
        </li>
      ))}
    </Scrollspy>
  </nav>
);

const TableOfContents = ({ item, tabs }) => {
  const [data, setData] = useState([]);
  const [url, setUrl] = useState([]);
  const [positionTop, setPositionTop] = useState(525); // Set initial value to 525px

  useEffect(() => {
    setPositionTop(525); // Adjust based on tabs
  }, [tabs]);

  const onScroll = useCallback(
    debounce((event) => {
      const headerHeight = 525; // Adjust this value based on your header height
      const scrollTop = event.target.documentElement.scrollTop;
      setPositionTop(scrollTop > headerHeight ? 65 : headerHeight - scrollTop);
    }, 100), // Debounce the scroll event handler
    []
  );

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    const datas = HeadingTableOfContents(item);

    if (datas.length > 0) {
      const dataArraykey = [];
      const dataArray = [];
      datas.forEach((post) => {
        dataArraykey.push(formatUrl(post.url));
        post.subMenus?.forEach((res) => dataArraykey.push(formatUrl(res.url)));
        dataArray.push(post);
        post.subMenus?.forEach((res) =>
          dataArray.push({
            ...res,
            url: formatUrl(res.url),
            subMenu: true,
          })
        );
      });
      setUrl(dataArraykey);
      setData(dataArray);
    }

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [item, onScroll]);

  return (
    <div className={container}>
      <TableOfContentsRenderer
        data={data}
        url={url}
        positionTop={positionTop}
      />
    </div>
  );
};

export default TableOfContents;