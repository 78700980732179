// extracted by mini-css-extract-plugin
export var bg = "MultiBrandSection-module--bg--+2Ip3";
export var bxTextTruncateEnd = "MultiBrandSection-module--bx--text-truncate--end--oNLef";
export var bxTextTruncateFront = "MultiBrandSection-module--bx--text-truncate--front--iEbsh";
export var containerSelect = "MultiBrandSection-module--containerSelect--wFZ2F";
export var description = "MultiBrandSection-module--description--QRAys";
export var flex = "MultiBrandSection-module--flex--IO8c2";
export var itemsCenter = "MultiBrandSection-module--items-center---MCFL";
export var justifyBetween = "MultiBrandSection-module--justify-between--MCzIE";
export var maxWidthFull = "MultiBrandSection-module--max-width-full--oGtqb";
export var mb2 = "MultiBrandSection-module--mb-2--Opwaq";
export var my8 = "MultiBrandSection-module--my-8--QvdJO";
export var row1 = "MultiBrandSection-module--row1--dKF8K";
export var select = "MultiBrandSection-module--select--PMlw3";
export var title = "MultiBrandSection-module--title--j0A0F";