import React from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { IconDownload } from "@tabler/icons-react";
import { Button, Text } from "@legion-ui/core";

import * as styles from "./CustomPageHeader.module.scss";

const DownloadSection = ({ platform, qrCodeUrl, downloadUrl }) => (
  <div className={styles.downloadSection}>
    <span className={styles.platformTitle}>
      <img alt={`${platform}-logo`} src={`/images/${platform}.svg`} />
      <Text size="18px" weight="700">
        {platform}
      </Text>
    </span>
    <img
      className={styles.qrCode}
      src={qrCodeUrl}
      alt={`qr code ${platform}`}
    />
    <Button
      className={styles.downloadButton}
      variant="soft"
      iconLeft={<IconDownload size={22} />}
      onClick={() => navigate(downloadUrl)}
      center={true}
    >
      Download Here
    </Button>
  </div>
);

DownloadSection.propTypes = {
  platform: PropTypes.string.isRequired,
  qrCodeUrl: PropTypes.string.isRequired,
  downloadUrl: PropTypes.string.isRequired,
};

export default DownloadSection;
