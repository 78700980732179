import React, { useState } from "react";
import * as styles from "./Utils.module.scss";
import { Text } from "@legion-ui/core";
import { useWindowScroll } from "beautiful-react-hooks";

import { IconArrowBarToUp, IconArrowUp, IconMinus } from "@tabler/icons-react";

const Utils = () => {
  const [show, setShow] = useState(false);
  const [hidden, setHidden] = useState(true);

  const onScroll = useWindowScroll();

  onScroll(() => {
    if (hidden && window.scrollY > 300) {
      setHidden(false);
    }
    if (!hidden && window.scrollY < 300) {
      setHidden(true);
    }
  });

  const toTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div
        className={`${styles.topButton} ${!hidden ? styles.active : null}`}
        onClick={toTop}
      >
        <IconArrowUp size={20} />
      </div>

      <div
        className={`${styles.topButton} ${!hidden ? styles.active : null} ${
          show ? styles.topButtonShow : null
        }`}
        onClick={toTop}
      >
        <IconArrowBarToUp size={20} color="white" />
      </div>

      <div
        className={`${styles.feedbackModal} ${show ? styles.active : null}`}
        style={{
          width: "245px",
          backgroundColor: "#6927DA",
          position: "fixed",
          bottom: "28px",
          right: "24px",
          padding: "16px",
          borderRadius: "8px",
          zIndex: 9999999,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <Text size="16px" weight="700" color="#ffffff">
            Give us your valuable feedback
          </Text>
          <span
            onClick={() => setShow(false)}
            style={{
              backgroundColor: "#5720B7",
              borderRadius: "50%",
              height: "20px",
              width: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <IconMinus size={16} color="white" />
          </span>
        </div>
        <Text
          as="p"
          size="12px"
          weight="600"
          color="#ffffff"
          mt="8px"
          mb="12px"
        >
          It will help us understand how we can serve you better
        </Text>
        <a
          href="https://submission.bromb.co/legion/Legion-Web"
          className={styles.anchor}
        >
          Give feedback
        </a>
      </div>
    </>
  );
};

export default Utils;