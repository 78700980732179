import React, { useState, useEffect, useMemo } from 'react';
import { useNavItems } from '../gatsby-theme-carbon/util/NavItems';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';

function NextPrevious() {
  let location = useLocation();
  const listNextPrev = useMemo(() => [], []);

  useNavItems().map((val) => {
    val.pages.map((value) => {
      if (value?.path !== '') {
        const link = value?.path.split('/').length > 2 ? value?.path.split('/').slice(0, 3).join('/') : value?.path;

        listNextPrev.push({
          path: value?.path,
          link: link || '',
          label: value?.label || '',
        });
      }
      return true;
    });
    return true;
  });

  const [dataNextPrev, setDataNextPrev] = useState({});

  useEffect(() => {
    const link =
      location.pathname.split('/').length > 1 ? location.pathname.split('/').slice(0, 3).join('/') : location.pathname;
    const checkListIndex = listNextPrev.map((val) => val.link).indexOf(link);
    setDataNextPrev({
      prev: listNextPrev[checkListIndex - 1],
      next: listNextPrev[checkListIndex + 1],
    });
  }, [location, listNextPrev]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: '2%',
        margin: '10rem 0 5rem 0',
      }}
    >
      <Link
        to={dataNextPrev?.prev?.path}
        activeClassName="active"
        className="nextPrevious"
        style={{ visibility: dataNextPrev?.prev?.link ? 'visible' : 'hidden' }}
      >
        <div>Previous</div>
        <div>{dataNextPrev?.prev?.label}</div>
      </Link>
      <Link
        to={dataNextPrev?.next?.path}
        activeClassName="active"
        className="nextPrevious"
        style={{ visibility: dataNextPrev?.next?.link ? 'visible' : 'hidden' }}
      >
        <div>Next</div>
        <div>{dataNextPrev?.next?.label}</div>
      </Link>
    </div>
  );
}

export default NextPrevious;
