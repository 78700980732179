import React, { useContext, useEffect, useState } from "react";
import slugify from "slugify";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "gatsby-theme-carbon/src/components/Layout";
import EditLink from "gatsby-theme-carbon/src/components/EditLink";
import CustomPageHeader from "../components/CustomPageHeader/CustomPageHeader";
import PageTabs from "gatsby-theme-carbon/src/components/PageTabs";
import Main from "gatsby-theme-carbon/src/components/Main";
import useMetadata from "gatsby-theme-carbon/src/util/hooks/useMetadata";
import LastModifiedDate from "gatsby-theme-carbon/src/components/LastModifiedDate";
import { legionTheme } from "@legion-ui/core";
import { ThemeProvider } from "@emotion/react";
import MultiBrandSection from "../components/MultiBrandSection/MultiBrandSection";
import TableOfContents from "../components/TableOfContents/TableOfContents";
import { release, themes } from "./Constant";
import NewRelease from "../components/NewRelease";
import NextPrev from "../components/NextPrevious";
import { themeContext } from "../../provider";
import Utils from "../components/utils";
import { Analytics } from "@vercel/analytics/react";

const Default = ({ pageContext, children, location, Title, Description }) => {
  const globalThemeContext = useContext(themeContext);
  const { frontmatter = {}, relativePagePath, titleType } = pageContext;
  const [activeTheme, setActiveTheme] = useState(themes[0]);
  const [newRelease, setNewRelease] = useState("");

  const {
    tabs,
    title,
    theme: frontmatterTheme,
    description,
    image,
    label,
    keywords,
    date,
    multiBrand,
    toc,
  } = frontmatter;

  useEffect(() => {
    // Get stored value
    const savedTheme = JSON.parse(localStorage.getItem("theme"));
    if (savedTheme) {
      setActiveTheme(savedTheme);
    } else {
      setActiveTheme({
        value: "Default",
        label: "Default Theme",
      });
    }
  }, []);

  const handleChangeTheme = (event) => {
    setActiveTheme(event);

    globalThemeContext.handleChangeCurrentTheme({
      label: event.label,
      value: event.value,
      heading: event.heading,
      body: event.body,
      bodySmall: event.bodySmall,
      url: event.url,
    });

    localStorage.setItem(
      "fonts",
      JSON.stringify({
        label: event.label,
        heading: event.heading,
        body: event.body,
        bodySmall: event.bodySmall,
        url: event.url,
      })
    );
    localStorage.setItem("theme", JSON.stringify(event));
  };

  const { interiorTheme } = useMetadata();

  // get the path prefix if it exists
  const {
    site: { pathPrefix },
  } = useStaticQuery(graphql`
    query pathPrefixQueryAndPathPrefixQuery {
      site {
        pathPrefix
      }
      site {
        pathPrefix
      }
    }
  `);

  // let gatsby handle prefixing
  const slug = pathPrefix
    ? location.pathname.replace(pathPrefix, "")
    : location.pathname;

  const getCurrentTab = () => {
    if (!tabs) return "";
    return (
      slug.split("/").filter(Boolean).slice(-1)[0] ||
      slugify(tabs[0], { lower: true })
    );
  };

  useEffect(() => {
    setNewRelease(localStorage.getItem("newRelease") || "test");
  }, []);

  const currentTab = getCurrentTab();

  const theme = frontmatterTheme || interiorTheme;

  return (
    <Layout
      tabs={tabs}
      homepage={false}
      theme={theme}
      pageTitle={
        title === "Let's start with Legion" ? "Legion Design System" : title
      }
      pageDescription={
        title === "Let's start with Legion"
          ? "Craft delightful and consistent product experience fasters with legion. The Multi-Brand Design System of Telkom Indonesia  as a source of truth that is used in the product development phase."
          : description
      }
      pageKeywords={keywords}
      titleType={titleType}
    >
      <>
        <Analytics />
        <ThemeProvider theme={legionTheme}>
          <Utils />

          <CustomPageHeader
            title={Title ? <Title /> : title}
            description={Description ? <Description /> : description}
            image={image}
            label={label}
          />

          {newRelease &&
            release.keyNewRelease !== newRelease &&
            title === "Start with Legion Design System" && (
              <NewRelease
                release={release.keyNewRelease}
                releaseDate={release.date}
                setNewRelease={setNewRelease}
              />
            )}
        </ThemeProvider>

        {multiBrand === true ? (
          <ThemeProvider theme={legionTheme}>
            <MultiBrandSection
              tribes={themes}
              activeTheme={activeTheme}
              handleChangeTheme={handleChangeTheme}
            />
            {tabs && (
              <PageTabs
                title={title}
                slug={slug}
                tabs={tabs}
                currentTab={currentTab}
              />
            )}
            <div className={`flex ${toc && "content"}`}>
              <Main padded>
                {children}
                <EditLink relativePagePath={relativePagePath} />
                <LastModifiedDate date={date} />
                <NextPrev />
              </Main>
              {toc && (
                <div className="toc">
                  <TableOfContents item={toc} tabs={tabs} />
                </div>
              )}
            </div>
          </ThemeProvider>
        ) : (
          <ThemeProvider theme={legionTheme}>
            {tabs && (
              <PageTabs
                title={title}
                slug={slug}
                tabs={tabs}
                currentTab={currentTab}
              />
            )}
            <div className={`flex ${toc && "content"}`}>
              <Main padded>
                {children}
                <EditLink relativePagePath={relativePagePath} />
                <LastModifiedDate date={date} />
                <NextPrev />
              </Main>

              {toc && (
                <div className="toc">
                  <TableOfContents item={toc} tabs={tabs} />
                </div>
              )}
            </div>
          </ThemeProvider>
        )}
      </>
    </Layout>
  );
};

export default Default;
