import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './CustomPageHeader.module.scss';

const RegularPageHeaderSection = ({ description, image, label, title }) => {
  return (
    <div className="bx--row">
      <div
        className={`bx--grid bx--col-lg-12 ${styles["flex"]}`}
        style={{ alignItems: "center" }}
      >
        <div className={styles.left}>
          <h3 className={styles.label}>{label}</h3>
          <h1 id="page-title" className={styles.text}>
            {title}
          </h1>
          <p className={styles.desc}>{description}</p>
        </div>
        <img src={image} alt={`banner-${title}`} />
      </div>
    </div>
  );
}

RegularPageHeaderSection.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default RegularPageHeaderSection;
