import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";

import * as styles from "./MultiBrandSection.module.scss";
import SquareIcon from "./square.svg";

const formatGroupLabel = (data) => (
  <div>
    <span>{data.label}</span>
  </div>
);

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    padding: "0 8px",
  }),
  option: (styles, { isSelected, isFocused }) => ({
    ...styles,
    backgroundColor: isSelected || isFocused ? "rgb(245, 243, 255)" : "white",
    color: isSelected || isFocused ? "rgb(135, 91, 247)" : "rgb(46, 48, 50)",
  }),
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles) => ({ ...styles }),
  menuList: (styles) => ({ ...styles, padding: "4px" }),
  indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
};

const MultiBrandSection = ({ activeTheme, handleChangeTheme, tribes }) => {
  const [scrollTop, setScrollTop] = useState(false);
  const [device, setDevice] = useState(false);

  const onScroll = useCallback(
    (event) => {
      let scroler = event.target.documentElement.scrollTop > 550;
      setScrollTop(scroler !== scrollTop ? scroler : scrollTop);
    },
    [scrollTop]
  );

  useEffect(() => {
    const scrollListener = window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", scrollListener);
    };
  }, [onScroll]);

  useEffect(() => {
    const userAgent =
      typeof window.navigator !== "undefined" ? navigator.userAgent : null;
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );
    setDevice(mobile);
  }, []);

  const ComponentSwitch = useCallback(() => {
    return (
      <div className="bx--row">
        <div
          className={`bx--col-lg-12 ${styles["flex"]} ${styles["justifyBetween"]} ${styles["itemsCenter"]} ${styles["row1"]}`}
        >
          <div>
          <div
              className={`${styles["flex"]} ${styles["itemsCenter"]} ${styles["mb2"]}`}
            >
              <object
                type="image/svg+xml"
                data={SquareIcon}
                aria-label="square icon"
              >
                Square Icon
              </object>
              <h1 id="brand-title" className={styles.title}>
                Selected Theme
              </h1>
            </div>
            <p id="brand-description" className={styles.description}>
              Select brand or tribe name to preview style
            </p>
          </div>
          <div
            className={styles.containerSelect}
            style={{
              position: scrollTop && !device ? "fixed" : "none",
              top: 85,
              right: 26,
              zIndex: scrollTop && !device ? 9999 : "none",
            }}
          >
            <Select
              onChange={handleChangeTheme}
              options={tribes}
              formatGroupLabel={formatGroupLabel}
              styles={colourStyles}
              defaultValue={{
                label: activeTheme.label,
                value: activeTheme.value,
                heading: activeTheme.heading,
                body: activeTheme.body,
                bodySmall: activeTheme.bodySmall,
                url: activeTheme.url,
              }}
            />
          </div>
        </div>
      </div>
    );
  }, [scrollTop, tribes, activeTheme, device, handleChangeTheme]);

  return (
    <div className={`${styles["bg"]} ${styles["my8"]}`}>
      <div className={`bx--grid`} style={{ marginRight: "7.5rem" }}>
        <ComponentSwitch />
      </div>
    </div>
  );
};

export default MultiBrandSection;
